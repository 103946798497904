import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConfigQuery from 'Query/Config.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';
import OGImage from 'Style/images/meta/peggy-sage-partage.jpg';
import { fetchQueryGet } from 'Util/Request/Query';

/** @namespace PeggysagePwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    ogType: state.MetaReducer.ogType
});

/** @namespace PeggysagePwa/Component/Meta/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace PeggysagePwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        ...SourceMetaContainer.propTypes,
        ogType: PropTypes.string,
        hrefLang: PropTypes.arrayOf(
            PropTypes.shape({
                store_code: PropTypes.string.isRequired,
                store_id: PropTypes.number.isRequired,
                store_url: PropTypes.string,
                full_path: PropTypes.string,
                absolute_url: PropTypes.string
            })
        )
    };

    static defaultProps = {
        ...SourceMetaContainer.defaultProps,
        default_ogType: 'website',
        ogType: '',
        hrefLang: []
    };

    state = {
        isLoading: false,
        location: null,
        urlRewrite: {}
    };

    componentDidUpdate(_prevProps, _prevState) {
        const currentLocation = window.location.href;
        const { location } = this.state;

        if (currentLocation !== location) {
            this.getHrefLang(currentLocation);
        }
    }

    _getOpenGraphType() {
        const { ogType, default_ogType } = this.props;

        return String(ogType || default_ogType);
    }

    _getTwitterSummaryCard() {
        return {
            'twitter:card': 'summary',
            'twitter:title': this._getTitle(),
            'twitter:description': this._getDescription(),
            'twitter:image': `${ location.origin }${ OGImage }`
        };
    }

    _getOpenGraphMetadata() {
        const openGraphs = {
            'og:type': this._getOpenGraphType(),
            'og:title': this._getTitle(),
            'og:description': this._getDescription(),
            'og:site_name': 'PEGGY SAGE',
            'og:url': location.href,
            'og:image': `${ location.origin }${ OGImage }`,
            'og:image:width': 1200,
            'og:image:height': 630,
            'og:image:type': 'image/jpeg'
        };

        return this._generateMetaFromMetadata(openGraphs, 'property');
    }

    /**
     * Override: add open graph meta
     * @returns {[string, unknown]}
     * @private
     */
    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode(),
            ...this._getTwitterSummaryCard()
        };

        return this._generateMetaFromMetadata(meta);
    }

    async getHrefLang(currentLocation) {
        const { pathname } = location;

        // FAILSAFE: Trim index.php if someone forgot to set "Use Web Server Rewrites" to "Yes"
        const trimmedParam = pathname.replace('index.php/', '');
        const finalURl = trimmedParam.replace(new RegExp(window.storeRegexText), '');

        await this._getHrefLang(finalURl).then(
            /** @namespace PeggysagePwa/Component/Meta/Container/MetaContainer/getHrefLang/then/finally/_getHrefLang/then */
            () => {
                this.setState({ location: currentLocation });
            }
        ).finally();
    }

    async _getHrefLang(path) {
        const { cacheLifetime } = this.props;

        fetchQueryGet(
            ConfigQuery.getHrefLang(path),
            'getHrefLang',
            cacheLifetime
        ).then(
            /** @namespace PeggysagePwa/Component/Meta/Container/MetaContainer/_getHrefLang/then/catch/fetchQueryGet/then */
            (res) => {
                const { hrefLang = {} } = res || {};
                this.setState({ hrefLang });
            }
        ).catch(
            /** @namespace PeggysagePwa/Component/Meta/Container/MetaContainer/_getHrefLang/then/catch */
            (err) => {
                if (!(err instanceof DOMException && err.name === 'AbortError')) {
                    throw err;
                }
                this.setState({ hrefLang: [] });
            }
        );
    }

    /**
     * Override: add open graph + force canonical url from window location
    */
    containerProps() {
        const { origin, pathname } = location;
        const { hrefLang = [] } = this.state || [];
        return {
            ...super.containerProps(),
            hrefLang,
            canonical_url: `${ origin }${ pathname }`,
            openGraphMetadata: this._getOpenGraphMetadata()
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
